@import "variables";
@import "framework/framework";
@import "../../node_modules/owl.carousel/dist/assets/owl.carousel.min";
@import "../../node_modules/owl.carousel/dist/assets/owl.theme.default.min";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";

html {
	background: $color-purple;
	font-family: $primary-font;
	font-size: 16px;
	line-height: 1;
	width: 100%;
	overflow-x: hidden;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}
 
body {
	color: $color-cream;
	width: 100%;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	position: relative;

	main {
		flex: 1;
	}
}

a {
	color: inherit;

	@include hover-focus {
		color: $color-cream;
	}
}

.btn {
	color: $color-purple;
	background-color: $color-cream;
	padding: rems(10) rems(24);
	font-size: rems(24);
	border: 1px solid $color-cream;

	@include hover-focus {
		color: $color-cream;
		background-color: $color-purple;
	}
}

header {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	background-color: $color-purple;

	@include tablet-down {
		background-color: transparent;

		&.active {
			background-color: $color-purple;
		}
	}

	.container {
		position: relative;

		nav {
			display: flex;
			align-items: center;
			justify-content: space-between;
			// margin: rems(10) 0;

			@include tablet-down {
				flex-direction: column;
			}

			.logo-wrapper {
				flex: 1;

				h1#logo {
					font-size: rems(52);
					color: $color-white;
					a {
						img {}
					}
				}
			}

			ul {
				list-style: none;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0;
				margin: 0;

				li {
					a {}
				}
			}

			.menu-wrapper {
				@include tablet-down {
					&:not(.active) {
						opacity: 0;
						pointer-events: none;
					}
				}

				ul#nav-menu {
					justify-content: space-between;
					gap: rems(40);

					@include tablet-down {
						flex-direction: column;
						padding-bottom: rems(40);
					}

					li {
						a {
							font-size: rems(23);
						}
					}
				}
			}

			ul.social {
				flex: 1;
				gap: rems(20);
				justify-content: flex-end;
				color: $color-white;

				@include tablet-down {
					padding-bottom: rems(30);

					&:not(.active) {
						opacity: 0;
						pointer-events: none;
					}
				}

				li {
					img {
						width: rems(20);
					}
				}
			}
		}

		.mobile-menu-wrapper {
			position: absolute;
			top: 6px;
			right: 0;

			.mobile-menu-icon {
				display: none;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				pointer-events: all;
				cursor: pointer;
				position: relative;
				z-index: 30;

				// ICON SIZE
				width: 35px;
				height: 40px;

				@include tablet-down {
					display: flex;
					margin: rems(20);
				}
			}

			.line {
				width: 100%;
				height: 2px;
				background-color: $color-white;
				margin: 4.5px;
				transition: transform 0.3s ease;
				transform-origin: center;


				&.active {
					transform: rotate(45deg) !important;
					position: absolute;
					top: 30%;

					&.active:nth-child(2) {
						display: none;
					}

					&.active:nth-child(3) {
						transform: rotate(-45deg) !important;
					}
				}
			}
		}

	}
}

main {
	section {

		h2 {
			text-align: center;
			font-size: rems(56);
			text-transform: uppercase;
		}
	}

	section#hero {
		img {
			height: auto;
			min-height: rems(700);
			width: 100%;
			object-fit: cover;
		}
	}

	section#music {

		.container {
			padding: 0 rems(40);
		}

		// MUSIC CAROUSEL
		#music-carousel {
			position: relative;

			// Item
			.item {
				display: flex;

				@include tablet-down {
					flex-direction: column;
					gap: rems(40);
				}

				.cover {
					flex: 1;
					display: flex;
					align-items: center;
					justify-content: center;

					img {
						max-width: rems(515);
					}
				}

				.info {
					flex: 1;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;

					.release {
						font-size: rems(50);
						text-align: center;
						color: $color-white;
					}

					.title {
						font-size: rems(50);
						// color: $color-orange;
						margin: rems(20) 0 0;
						text-align: center;
					}

					h4 {
						font-size: rems(20);
						margin-bottom: rems(40);
						color: $color-white;
					}

					.btn {}
				}
			}

			.owl-stage-outer {}

			.owl-nav {

				// Nav Buttons
				button {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);

					&.owl-prev {
						left: rems(-35);
					}

					&.owl-next {
						right: rems(-35);
					}

					@include phone-down {
						&.owl-prev {
							left: rems(-25);
						}

						&.owl-next {
							right: rems(-25);
						}
					}

					.arrow {
						i {
							font-size: rems(43);
							color: $color-cream;

							@include hover-focus {
								transform: scale(1.1);
							}
						}
					}
				}
			}

		}
	}

	section#videos {
		padding: rems(50) 0;

		.container {
			h2 {}

		}

		#video-carousel {
			.embed-container {}

			.owl-nav {

				// Nav Buttons
				button {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);

					&.owl-prev {
						left: rems(15);
					}

					&.owl-next {
						right: rems(15);
					}

					.arrow {
						i {
							font-size: rems(43);
							color: $color-cream;

							@include hover-focus {
								transform: scale(1.1);
							}
						}
					}
				}
			}
		}

		.container {
			padding-top: rems(60);
			text-align: center;

			a.btn {}
		}
	}

	section#tour {
		padding: rems(50) 0;

		.container {
			display: flex;
			align-items: center;
			flex-direction: column;

			#tour-dates {
				width: 100%;

				.event-group {
					display: flex;
					gap: rems(16);
					margin: rems(24) 0 rems(40);

					@include tablet-down {
						flex-direction: column;
						text-align: center;
					}

					.event-date {
						flex: .7;
					}

					.event-venue {
						flex: 1;
					}

					.event-location {
						flex: 1;
					}

					.event-links {
						flex: .7;
						display: flex;
						justify-content: flex-end;

						@include tablet-down {
							justify-content: center;
						}

						a.btn {
							font-size: rems(22);
							padding: rems(5) rems(24);
						}
					}
				}

				.no-events {
					text-align: center;
					display: block;
					padding-bottom: rems(40);
				}
			}

			.btn {}
		}
	}

	#signup {
		.container {
			display: flex;
			flex-direction: column;
			align-items: center;

			h3 {
				font-size: rems(32);
			}

			form {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				width: 100%;
				// max-width: rems(600);

				.group-wrapper {
					display: flex;
					align-items: center;
					justify-content: center;
					gap: rems(10);
					width: 100%;

					.form-group {
						width: 100%;
						margin-bottom: rems(20);
						position: relative;
						display: flex;


						input,
						select {
							width: 100%;
							border: none;
							border-bottom: 1px solid white;
							background: transparent;
							color: white;
							padding: rems(10) 0;
							font-size: rems(16);

							&:focus {
								outline: none;
								border-bottom-color: $color-white;
							}

							&::placeholder {
								color: $color-white;
								font-size: 16px;
							}
						}
					}

					.form-group {
						flex: 1;
					}

					// Hide and check the checkboxes
					.input-group {
						display: none;
					}


				}

				button {
					margin-top: 20px;
					padding: 10px 30px;
					background-color: $color-cream;
					color: $color-purple;
					font-size: 16px;
					border: 1px solid $color-cream;
					cursor: pointer;
					text-transform: uppercase;
					display: block;
					text-align: center;

					&:hover {
						background-color: #333;
					}
				}
			}
		}
	}


}

footer {
	padding: rems(40);
	text-align: center;
	font-size: rems(14);
}