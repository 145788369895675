$fa-font-path: "../font";
$html-font-size: 16;
$color-white: #fff;
$color-black: #000;
$color-cream: #FFF4E5;
$color-orange: #FC883A;
$color-purple: #231D21;

// $color-cream: #FFF4E5;
$color-cream: #acbbbc;
$color-sky-blue: #acbbbc;

@font-face {
  font-family: 'Diamonds';
  src: url('../font/DiamondsThin.woff2') format('woff2'),
    url('../font/DiamondsThin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}



$primary-font: 'Diamonds', Arial, sans-serif;